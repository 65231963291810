class EventDispatcher {
  constructor() {
    this.queue = {};
  }

  publish(event, data) {
    let queue = this.queue[event];
    let i = 0;

    if (typeof queue === "undefined") {
      return false;
    }
    while (queue.length > i) {
      queue[i++](data);
    }

    return true;
  }

  subscribe(event, callback, singleton = false) {
    if (typeof this.queue[event] === "undefined") {
      this.queue[event] = [];
    }
    if ( !singleton ) {
      this.queue[event].push(callback);
    } else {
      if (Number.isInteger(singleton)) {
        if (this.queue[event].length === 0) {
          this.queue[event].push(callback);
        }
      } else {
        if (this.queue[event].indexOf(callback) === -1) {
          this.queue[event].push(callback);
        }
      }
    }
  }

  // For "unsubscribe" method the callback parameter is optional. Without it the whole event will be removed,
  // instead of just one subscibtion. This is enough for a simple pub/sub implementation.
  unsubscribe(event, callback) {
    let queue = this.queue;
    console.log("event dispatcher unsubscribe", event);
    if (typeof queue[event] !== "undefined") {
      if (typeof callback === "undefined") {
        delete queue[event];
      } else {
        this.queue[event] = queue[event].filter(function(sub) {
          return sub !== callback;
        });
      }
    }
  }
}

let eventDispatcher = new EventDispatcher();

export default eventDispatcher;

